.about-container {
    color: rgb(250, 250, 250);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.9);
}

.about {
    max-width: 90%;
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    opacity: 0;
    animation-name: container;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

@keyframes container {
    0%{opacity: 0;}
    100%{opacity: 1;}
}


.about-container > h2 {
    padding-bottom: 2rem;
    font-size: 2.5rem;
    font-family: 'Rowdies', cursive;
    color: #db9200;
}

.about div {
    width: 100%;
    flex: 1;
}

.about div img {
    width: 100%;
}

.about-content {
    flex: 2;
    margin-left: 1.5rem;
}

.about-content p {
    font-size: 1.2rem;
    text-align: justify;
    font-weight: lighter;
    line-height: 1.7rem;
}

.tech-familiar ul li {
    width: 80%;
    background-color: whitesmoke;
    color: black;
    padding: 0.5rem;
}

.tech-familiar ul li:nth-child(odd) {
    background-color: #302f46;
    color: white;
}

.tech-familiar ul li:last-child {
    margin-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
    .about-container {
        padding-top: 3rem;
    }

    .about-container > h2 {
        font-size: 1.5rem;
    }

    .about {
        flex-direction: column;
        justify-content: center;
        width: 80%;
    }
    
    .about-content {
        flex: 1;
        margin-left: 0rem;
    }
    .tech-familiar ul li {
        width: 80%;
    }
}