@import url('https://fonts.googleapis.com/css2?family=Rowdies:wght@300&display=swap');


/* universal styling */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
  color: rgb(255, 255, 255);
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
}

/* App styling */
.landing-container {
  background: url('./assets/img/background.jpg');
  background-position: center;
  background-size: cover;
  position: relative;
}

.bg-container {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
}