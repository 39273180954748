
.home {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: rgb(250, 250, 250);
}

.home-grey-bg {
    width: 100%;
    height: 100vh;
}

.home-container {
    display: flex;
    justify-content: center;
    width: 80%;
    flex-direction: row-reverse;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.content-container {
    flex: 1;
}

.content-container h5 {
    font-family: 'Rowdies', cursive;
    font-size: 2.5rem;
    font-weight: bolder;
    line-height: 2.5rem;
    transition: 1s ease-in-out;
    animation-name: home-heading;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

@keyframes home-heading {
    0%{transform: skewX(0deg)}
    25%{transform: skewX(20deg)}
    50%{transform: skewX(-20deg)}
    100%{transform: skewX(0deg)}
}

.content-container p {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: -3rem;
    margin-left: -100rem;
    transition: 1s ease-in-out;
    animation-name: home-paragraph;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

@keyframes home-paragraph {
    0%{margin-left: -100rem}
    100%{margin-left: 0}
}

.link-button {
    display: flex;
    opacity: 0;
    transition: 1s ease-in-out;
    animation-name: home-social-links;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 2.5s;
    animation-delay: 1s;
}

@keyframes home-social-links {
    0% {opacity: 0;}
   
    100%{opacity: 1;}
}

.link-button a {
    color: white;
    margin-right: 0.5rem;
    padding: 0.5rem;
    font-size: 0.7rem;
}

.view-resume {
    border: solid 1px #db9200;
    transition: ease-in-out 0.5s;
    border-radius: 3px;
}

.view-resume:hover {
    background: #db9200;
}

.hire-me {
    background: #db9200;
    border: none;
    transition: 0.5s ease-in-out;
    animation: hire-me 1s infinite;
    animation-delay: 1s;
    border-radius: 3px;
}

@keyframes hire-me {
    0% {transform: scale(1)}
    25% {transform: scale(1.1)}
    50%{transform: scale(1.2)}
    75% {transform: scale(1.1)}
    100%{transform: scale(1)}
}

.hire-me:hover {
    border: solid 1px #db9200;
    background: none;
    animation-name: no-animation;
    transform: scale(1.2)
}

.home-social-icon {
    display: flex;
    margin-top: 1rem;
    justify-content: left;
    transition: 1s ease-in-out;
    animation-name: home-links;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

@keyframes home-links {
    0% {opacity: 0;}
    25% {opacity: 0.5; transform: skew(-10deg)}
    50%{opacity: 0.8;}
    75% {transform: skew(-20deg)}
    100%{opacity: 1;transform: skew(0deg)}
}

.home-social-icon a {
    margin: auto 0.4rem;
    color: white;
    font-size: 1.2rem;
    transition: 0.5s ease-in-out;
}

.home-social-icon a:hover {
  transform: scale(1.2);
}

.avatar-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center; 
    position: relative;
}


.avatar-box img {
    width: 100%;
    transform: rotateY(0deg);
    opacity: 0;
    transition: 1s ease;
    animation-name: home-image;
    animation-duration: 2s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

@keyframes home-image {
    0% {opacity: 0; transform: rotateY(180deg);}
    100% {opacity: 1;}
}


.link-button {
    display: flex;
    align-items: center;
    justify-content: left;
}


@media only screen and (max-width: 768px) {

    .home {
        background-size: cover;
       
    }

    .home-container {
        flex-direction: column-reverse;
        justify-content: center;
        align-content: center;
        width: 70%;
        padding: 2rem auto;
    }

    .avatar-container {
        flex: 1;
    }

    .avatar-box img {
        width: 70%;
    }

    .content-container {
        text-align: center;
    }

    .content-container h5 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-top: 0.5rem;

    }

    .content-container p {
        margin-top: -2rem;
    }

    .link-button {
        justify-content: center;
    }

    .home-social-icon {
        justify-content: center;
    }
    
}

@media only screen and (max-width: 500px) {
    .avatar-box img {
        width: 100%;
    }
}