.contact-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    color: rgb(250, 250, 250);
}

.contact-container h2 {
    font-family: 'Rowdies', cursive;
    font-size: 2.5rem;
    color: #db9200;
}

.contact:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: gray;
}

.contact {
    max-width: 75%;
    position: relative;
    height: auto;
    margin: 0 auto;
    opacity: 0;
    animation-name: contact;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}

@keyframes contact {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.contact ul {
    list-style: none;
}

.contact ul li {
background-color: #1e1f22;
margin-bottom: 20px;
width: 50%;
padding: 20px;
border-radius: 20px;
}

.contact ul li:nth-child(2) {
    margin-bottom: 3rem;
}

.contact ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-70px);
}

.contact ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(70px);
}

.contact ul li::before {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: gray;
    top: 0px;
  }

.contact ul li:nth-child(odd)::before {
    transform: translate(50%, -50%);
    right: -30px;
}

.contact ul li:nth-child(even)::before {
transform: translate(-50%, -50%);
left: -30px;
}

input, textarea {
    display: block;
    background: transparent;
    margin-bottom: .5rem;
    border: none;
    border-bottom: whitesmoke 1px solid;
    padding: 0.5rem;
    width: 90%;
    outline: none;
    color: whitesmoke;
}

.submit-btn {
    width: 30%;
    background-color: #db9200;
    padding: 0.5rem;
    transition: 0.5s ease-in-out;
    border: none;
    cursor: pointer;
    border-radius: 5px;
}

.submit-btn:hover {
    background-color: #926100;  
}


.timeline-content > p {
    font-weight: 200;
    font-size: .9rem;
    text-align: justify;
}

.timeline-content div p{
    font-size: 0.9rem;
}

.timeline-content .social-contact div {
    display: flex;
    align-items: center;
    margin: -1rem auto;  
    align-content: center;
}

.timeline-content .social-contact div span{
    margin-right: 0.5rem;
}


@media only screen and (max-width: 768px) {
    
    .contact-container {
       padding-top: 3rem;
       padding-bottom: 3rem auto;

    }

    .contact-container h2 {
        font-size: 1.5rem;
    }

    .contact:before {
        content: "";
        position: absolute;
        top: 0%;
        left: 1px;
        /* transform: translateX(-50%); */
        width: 2px;
        height: 100%;
        background-color: gray;
    }

    .contact ul li {
        background-color: #1e1f22;
        color: white;
        margin-bottom: 20px;
        width: 80%;
        padding: 1rem;
    }

    .contact ul li:nth-child(odd) {
        float: none;
        transform: translateX(0px);
        margin-left: 5%;
    }
    
    .contact ul li:nth-child(even) {
        float: none;
        transform: translateX(0px);
        margin-left: 5%;
    }

    .contact ul li:nth-child(odd)::before {
        transform: translate(0);
        left: -7.7%;
        height: 15px;
        width: 15px;
    }
    
    .contact ul li:nth-child(even)::before {
        transform: translate(0);
        left: -7.7%;
        height: 15px;
        width: 15px;
    }
}