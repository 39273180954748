@import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@600&display=swap');

.side-nav-content {
    position: fixed;
    top: 30%;
    left: 2%;
    overflow: hidden;
    width: 10px;
    transition: 0.5s ease-in;
    z-index: 2;
    opacity: 0;
    animation-name: sidenav;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-delay: 4s;
}

@keyframes sidenav {
    0%{opacity: 0;}
    100%{opacity: 1;}
}

.toggle {
    font-size: 1.8rem;
    cursor: pointer;
    transition: 0.5s ease-in-out;
}

.toggle:hover {
    transform: scale(1.05);
}

.menu-container {
    white-space: nowrap;
}

.menu-container ul li {
    margin: 2rem auto;
}

.menu-container ul li a {
    display: flex;
    align-items: center;
}

.menu-icon {
    color: whitesmoke;
    background-color: #db9200;
    padding: 0.4rem 0.5rem;
    border-radius: 20%;
    font-size: 1rem;
    transition: 0.5s ease-out;
}

.menu-icon:hover, .menu-name:hover {
    background-color: #926100;  
}

.menu-name {
    font-size: 1rem;
    background-color: #db9200;
    padding: 0.3rem 0.5rem;
    margin-left: 0.6rem;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: white;
    font-family: 'Edu VIC WA NT Beginner', cursive;
    border-bottom: 1px whitesmoke solid;
}


@media only screen and (max-width: 650px) {
    .side-nav-content {
        top: 25%;
    }

    .toggle {
        display: none;
    }

    .menu-name {
        display: none;
    }
}

@media only screen and (max-height: 410px) {
    .side-nav-content {
        top: 10%;
    }

    .menu-container ul li {
        margin: 1.5rem auto;
        font-size: 1.1rem;
    }
}
