@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');

.portfolio-container {
    color: rgb(217, 217, 217);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.9);
}

.portfolio-container > h2 {
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 2.5rem;
    font-family: 'Rowdies', cursive;
    color: #db9200;
}

.portfolio {
    opacity: 0;
    animation-name: portfolio;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-delay: 1s;
}


@keyframes portfolio {
    0%{opacity: 0;}
    100%{opacity: 1;}
}


.project-container {
    max-width: 80vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    padding-bottom: 1rem;

}


.project-card {
    text-align: left;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.7s;
    background-color: #302f46;
    cursor: pointer;
    border-radius: 2%;
    padding: 1rem;
}

.project-card:hover {
    transform: scale(1.05);
}

.card-img img {
    width: 100%;
}


.project-card .card-body .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: -0.5rem;
}

.project-card .card-body .desc {
    margin-bottom: .9rem;
    font-size: 0.9rem;
    font-weight: lighter;
    line-height: 20px;
}

.card-body .tools-used {
    display: flex;
    justify-content: space-around;
}

.card-body .tools-used p {
    font-size: .9rem;
    background-color: rgb(16, 17, 16);
    padding: .3rem .5rem;
    border-radius: 5%;
    font-size: .8rem;
}

.card-footer {
    display: flex;
    justify-content: space-around;
    text-align: center;
    padding: 0.5rem;
    /* margin: 1rem auto;     */
}

.card-url a {
    color: rgb(217, 217, 217);
    border: none;
    padding: 0.5rem;
    width: 25px;
    height: 25px;
    transition: 0.5s ease;
    font-size: 0.7rem;
}

.card-url a:hover {
    border: solid #db9200 1px;
    border-radius: 5px;
    padding: 0.5rem;
}

.project-card:last-child {
    margin-bottom: 4rem;
}

@media only screen and (max-width: 1000px) {
    .project-container {
        max-width: 80vw;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .project-container {
        max-width: 90vw;
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 700px) {
    .project-container {
        max-width: 85vw;
        grid-template-columns: 1fr;
    }

    .portfolio-container > h2 {
        padding-top: 1rem;
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
    }
}

@media only screen and (min-height: 1367px) {
    .project-container {
        padding-bottom: 4rem;
    
    }
}
